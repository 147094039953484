import SimpleState from '@schuettflix/util-simple-state'
import useSimpleState from '@schuettflix/util-simple-state-react'

import { globalStates } from '../globalStates'

export const withTransportId =
  <TProps extends { transportId: string }>(Component: React.ComponentType<TProps>) =>
  (props: Omit<TProps, 'transportId'>) => {
    const [transportId] = useSimpleState(globalStates.transportId)
    const _Component = Component as React.ComponentType<{ transportId: string }>
    return <_Component {...props} transportId={transportId} />
  }

export const withTransportIdSimpleState =
  (Component: React.ComponentType<{ transportId: SimpleState<string> }>) => () => {
    return <Component transportId={globalStates.transportId} />
  }
