import { appInternal, shorthandComponents } from '@schuettflix/app-internal'
import { DemoEnvironment } from '@schuettflix/util-demo-environment'

import { withTransportIdSimpleState } from '../utils/higherOrderComponents'

const { createReactComponent } = DemoEnvironment
const { AssignTransportAction } = shorthandComponents

export const TransportInternalComponent = withTransportIdSimpleState(createReactComponent(appInternal))
export const TransportInternalShorthandComponentAssignTransportAction = withTransportIdSimpleState(
  createReactComponent(AssignTransportAction.component)
)
