import React from 'react'
import ReactDOM from 'react-dom/client'

import { appInternal, shorthandComponents as appInternalShorthandComponents } from '@schuettflix/app-internal'
import { widgetTransportDriver, widgetTransportList } from '@schuettflix/app-transport'
import {
  JobExchangeIcon,
  ListingIcon,
  OrdersIcon,
  SearchIcon,
  SettingsIcon,
  VehicleDriverIcon,
} from '@schuettflix/icons-react'
import { DemoEnvironment } from '@schuettflix/util-demo-environment'
import SimpleState from '@schuettflix/util-simple-state'

import {
  TransportInternalComponent,
  TransportInternalShorthandComponentAssignTransportAction,
} from './components/TransportInternalComponent'
import { WidgetCarriersTransports } from './components/WidgetCarriersTransports'
import { WidgetClientsTransports } from './components/WidgetClientsTransports'
import { WidgetGenericTransports } from './components/WidgetGenericTransports'
import { WidgetJobExchange } from './components/WidgetJobExchange'
import { WidgetSuppliersTransports } from './components/WidgetSuppliersTransports'
import { WidgetTransportDriver } from './components/WidgetTransportDriver'
import {
  WidgetTransportsScopedByOrder,
  widgetTransportsScopedByOrder,
} from './components/WidgetTransportsScopedByOrder'
import {
  WidgetTransportsSearchScopedByOrder,
  widgetTransportsSearchScopedByOrder,
} from './components/WidgetTransportsSearchScopedByOrder'
import { globalStates } from './globalStates'
import './index.css'

const { NavItem, NavDivider, installApp } = DemoEnvironment

installApp(widgetTransportDriver, {
  hostAppVersion: '1.0.0',
  baseUrlTransport: globalStates.baseUrlTransport,
})
installApp(widgetTransportList, {
  hostAppVersion: '1.0.0',
  baseUrlTransport: globalStates.baseUrlTransport,
})
installApp(appInternal, {
  hostAppVersion: '1.0.0',
  baseUrlTransport: globalStates.baseUrlTransport,
})
installApp(appInternalShorthandComponents.AssignTransportAction.component, {
  hostAppVersion: '1.0.0',
  baseUrlTransport: globalStates.baseUrlTransport,
})
installApp(widgetTransportsScopedByOrder, {
  // hostAppVersion: '1.0.0',
  baseUrlTransport: globalStates.baseUrlTransport,
})
installApp(widgetTransportsSearchScopedByOrder, {
  // hostAppVersion: '1.0.0',
  baseUrlTransport: globalStates.baseUrlTransport,
})

const calculateListSubPath = () => {
  const transportId = globalStates.transportId.value
  return transportId ? `detail/${transportId}` : ''
}
const listSubPath = new SimpleState(calculateListSubPath())
globalStates.transportId.onUpdate(() => {
  listSubPath.value = calculateListSubPath()
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <DemoEnvironment
      predefinedUserCredentials={[
        {
          label: 'Platform Admin',
          userName: 'platform-admin',
          password: 'Test1234',
        },
        {
          label: 'Client of the orders [Org-ID: 5]',
          userName: 'qa-client-admin',
          password: 'Test1234',
        },
        {
          label: 'Supplier without preferred carriers [Org-ID: 21]',
          userName: 'supplier-no-preferred-carriers',
          password: 'Test1234',
        },
        {
          label: 'Supplier with preferred carriers (custom) [Org-ID: 22]',
          userName: 'supplier-with-preferred-carriers-custom',
          password: 'Test1234',
        },
        {
          label: 'Supplier with preferred carriers (default) [Org-ID: 23]',
          userName: 'supplier-with-preferred-carrier-default',
          password: 'Test1234',
        },
        {
          label: 'Carrier without subcontracting [Org-ID: 24]',
          userName: 'carrier-without-subcontracting',
          password: 'Test1234',
        },
        {
          label: 'Carrier with-subcontracting [Org-ID: 25]',
          userName: 'carrier-with-subcontracting',
          password: 'Test1234',
        },
      ]}
      shorthands={[
        {
          label: appInternalShorthandComponents.AssignTransportAction.label,
          component: <TransportInternalShorthandComponentAssignTransportAction />,
        },
      ]}
    >
      <NavItem title="Driver" icon={VehicleDriverIcon} content={<WidgetTransportDriver baseName="driver" />} />
      <NavDivider headline="Listings" />
      <NavItem
        title="Job Exchange"
        icon={JobExchangeIcon}
        content={<WidgetJobExchange type="job-exchange" baseName="job-exchange" className="!h-[calc(100vh-128px)]" />}
        subPath={listSubPath}
      />
      <NavItem
        title="Generic Transports"
        icon={ListingIcon}
        content={
          <WidgetGenericTransports type="generic" baseName="generic-transports" className="!h-[calc(100vh-128px)]" />
        }
        subPath={listSubPath}
      />
      <NavItem
        title="Carrier's Transports"
        icon={ListingIcon}
        content={
          <WidgetCarriersTransports type="carrier" baseName="carriers-transports" className="!h-[calc(100vh-128px)]" />
        }
        subPath={listSubPath}
      />
      <NavItem
        title="Supplier's Transports"
        icon={ListingIcon}
        content={
          <WidgetSuppliersTransports
            type="supplier"
            baseName="suppliers-transports"
            className="!h-[calc(100vh-128px)]"
          />
        }
        subPath={listSubPath}
      />
      <NavItem
        title="Client's Transports"
        icon={ListingIcon}
        content={
          <WidgetClientsTransports type="client" baseName="clients-transports" className="!h-[calc(100vh-128px)]" />
        }
        subPath={listSubPath}
      />
      <NavDivider headline="Order Hooks" />
      <NavItem title="Transports Hook" icon={OrdersIcon} content={<WidgetTransportsScopedByOrder />} />
      <NavItem title="Transports Search Hook" icon={SearchIcon} content={<WidgetTransportsSearchScopedByOrder />} />
      <NavDivider />
      <NavItem title="Sandbox" icon={SettingsIcon} content={<TransportInternalComponent />} />
    </DemoEnvironment>
  </React.StrictMode>
)
