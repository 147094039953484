import SimpleState from '@schuettflix/util-simple-state'

import { LOCALSTORAGE_KEY_API_TRANSPORTURL_CURRENT, LOCALSTORAGE_KEY_TRANSPORT_ID_CURRENT } from './utils/constants'
import { initializeState } from './utils/initializeState'

export const globalStates = {
  transportId: new SimpleState(localStorage.getItem(LOCALSTORAGE_KEY_TRANSPORT_ID_CURRENT) || '0'),
  baseUrlTransport: new SimpleState(
    initializeState({
      localStorageKey: LOCALSTORAGE_KEY_API_TRANSPORTURL_CURRENT,
      queryParamKey: 'baseUrlTransport',
      fallbackValue: import.meta.env.VITE_BASE_URL_TRANSPORT,
    })
  ),
}

globalStates.transportId.onUpdate(value => {
  localStorage.setItem(LOCALSTORAGE_KEY_TRANSPORT_ID_CURRENT, String(value))
})
globalStates.baseUrlTransport.onUpdate(value => {
  localStorage.setItem(LOCALSTORAGE_KEY_API_TRANSPORTURL_CURRENT, value)
})
