type InitializeStateConfig = {
  localStorageKey?: string
  queryParamKey?: string
  fallbackValue: string
}

export const initializeState = ({ localStorageKey, queryParamKey, fallbackValue }: InitializeStateConfig) => {
  const value = getValue({ localStorageKey, queryParamKey, fallbackValue })
  if (localStorageKey) {
    localStorage.setItem(localStorageKey, value)
  }
  return value
}

const getValue = ({ localStorageKey, queryParamKey, fallbackValue }: InitializeStateConfig) => {
  if (queryParamKey) {
    const urlParams = new URLSearchParams(window.location.search)
    const urlParamValue = urlParams.get(queryParamKey)
    if (urlParamValue) {
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, urlParamValue)
      }
      return urlParamValue
    }
  }

  if (localStorageKey) {
    const localStorageValue = localStorage.getItem(localStorageKey)
    if (localStorageValue) {
      return localStorageValue
    }
  }

  return fallbackValue
}
